//used for decode jwt
export const parseJwt = {
    created() {
      this.parseJwt()
    },
    data() {
        return{
            tokenData:[]
        }
    },
    methods: {
        parseJwt () {
            var base64Url = localStorage.getItem('token').split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            this.tokenData = JSON.parse(jsonPayload)
            return this.tokenData
        }
    }
}